<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="用户名">
              <el-input v-model="searchForm.loginName" placeholder="请输入要搜索的用户名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="员工姓名">
              <el-input v-model="searchForm.userName" placeholder="请输入要搜索的员工姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号">
              <el-input v-model="searchForm.phone" placeholder="请输入要搜索的手机号" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button type="primary" @click="$router.push({ name: 'systemManagementEmployeeEdit', params: { editMode: 'add' } })">
            添加用户
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="230">
            <template v-slot="scope">
              <el-link v-if="scope.row.admin === '0'" type="primary" class="text-btn" @click="adminToFirm(scope.row, 1)">
                设置管理员
              </el-link>
              <el-link v-if="scope.row.admin === '1'" type="danger" class="text-btn" @click="adminToFirm(scope.row, 0)">
                取消管理员
              </el-link>
              <el-link v-if="scope.row.status === '10'" type="primary" class="text-btn" @click="enableOrDisable(scope.row.employeeCode, '10')">
                启用
              </el-link>
              <el-link v-if="scope.row.status === '20'" type="danger" class="text-btn" @click="enableOrDisable(scope.row.employeeCode, '20')">
                禁用
              </el-link>
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'systemManagementEmployeeEdit', params: { editMode: 'edit' }, query: { employeeCode: scope.row.employeeCode } })">
                编辑
              </el-link>
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'systemManagementEmployeeDetail', query: { employeeCode: scope.row.employeeCode } })">
                查看
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      baseSearchForm: {
        userName: '',
        loginName: '',
        phone: ''
      },
      columnOption: [
        { label: '用户名', prop: 'account', formatter: this.utils.isEffective, canSet: false, fixed: 'left', minWidth: 140 },
        { label: '姓名', prop: 'name', formatter: this.utils.isEffective, minWidth: 100 },
        { label: '所属角色', prop: 'roleList', formatter: (row, col, cell) => this.utils.roleFormat(cell), minWidth: 320 },
        { label: '手机号码', prop: 'mobile', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'status', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'employeeStatus'), minWidth: 140 },
      ]
    }
  },
  computed: {},
  methods: {
    getList () {
      this.loading.list = true
      this.api.system.employee.userList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    adminToFirm (row, type) {
      let api = this.api.system.employee.addAdminToFirm
      if (type === 0) {
        api = this.api.system.employee.cancelAdminToFirm
      }
      api(row.itFirmCode, row.employeeCode).then(result => {
        if (result.data.success === true) {
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(() => {})
    },
    enableOrDisable (employeeCode, status) {
      let api = ''
      let success = ''
      if (status === '10') {
        api = this.api.system.employee.enable
        success = '已启用'
      } else {
        api = this.api.system.employee.disable
        success = '已禁用'
      }
      api(employeeCode).then(result => {
        if (result.data.success === true) {
          this.$message.success(success)
          this.getList()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(() => {})
    }
  }
}
</script>

<style>

</style>
